.notification {
  & + .notification {
    margin-top: 0.5rem;
  }

  .notificationDate {
    --bs-text-opacity: 0.7;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity));
  }
}
