// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/functions";

$dynamo-color: #a21caf;

// 2. Include any default variable overrides here
$enabled_rfs: false;

$component-active-bg: $dynamo-color;
$focus-ring-color: rgba($dynamo-color, 0.25);

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

// 4. Include any default map overrides here
$brand-colors: (
  "dynamo": $dynamo-color,
);
$theme-colors: map-merge($theme-colors, $brand-colors);

$custom-font-sizes: (
  7: $font-size-base * 0.75,
  8: $font-size-base * 0.5,
);

$font-sizes: map-merge($font-sizes, $custom-font-sizes);

// 5. Include remainder of required parts
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "bootstrap/scss/utilities";

$custom-colors-text: (
  "dynamo": shade-color($dynamo-color, 60%),
);
$custom-colors-bg-subtle: (
  "dynamo": tint-color($dynamo-color, 80%),
);
$custom-colors-border-subtle: (
  "dynamo": tint-color($dynamo-color, 60%),
);

$theme-colors-text: map-merge($theme-colors-text, $custom-colors-text);
$theme-colors-bg-subtle: map-merge($theme-colors-bg-subtle, $custom-colors-bg-subtle);
$theme-colors-border-subtle: map-merge($theme-colors-border-subtle, $custom-colors-border-subtle);

$utilities: map-merge(
  $utilities,
  (
    "font-size":
      map-merge(
        map-get($utilities, "font-size"),
        (
          rfs: false,
          responsive: true,
        )
      ),
    "background-color":
      map-merge(
        map-get($utilities, "background-color"),
        (
          responsive: true,
        )
      ),
    "border-top":
      map-merge(
        map-get($utilities, "border-top"),
        (
          responsive: true,
        )
      ),
  )
);

@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
// @import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
// @import "bootstrap/scss/accordion";
// @import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
// @import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";
@import "bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "bootstrap/scss/utilities/api";

@import "date-picker/calendar.scss";
@import "date-picker/date-picker.scss";

// 8. Add additional custom code here
.flex-min-width-0 {
  min-width: 0;
}

#notifications-panel {
  position: relative;
  z-index: 1000;
}

#card-modal {
  position: relative;
  z-index: 1000;
}

.PhoneInputInput {
  border: 0;
}
